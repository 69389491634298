.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.custom-label-style1 {
  color: blue;
  font-size: 16px;
}

.sidebarr {
  margin-top: '55px';
}

.bg-green {
  background-color: #8ceb8c;
}

.bg-yellow {
  background-color: #ffff00;
}

.inputt {
  justify-content: 'center';
  align-items: 'center';
}

/* .css-10hburv-MuiTypography-root{
  font-weight: 600 !important;
} */
.subList {
  font-weight: 600 !important;
}

@media print {

  /* Hide all elements outside the modal when printing */
  body>*:not(.modal) {
    display: none !important;
  }

  /* Display only the modal content */
  .modal-content {
    display: block !important;
  }

  /* Ensure the modal content takes up the full page */
  .modal-dialog {
    max-width: 100%;
    width: auto;
    margin: 0;
  }

  .modal-header,
  .modal-footer {
    display: none !important;
  }

  /* Adjust margins for better printing */
  @page {
    margin: 0;
  }

  /* Adjust padding and background */
  .modal-body {
    padding: 1rem;
    background: none;
  }

  /* Remove borders */
  .modal-content,
  .modal-dialog,
  .modal-body {
    border: none;
  }
}

.confirm-button {
  background-color: #441fd9; 
  color: #fff; 
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0 10px; 
  font-size: 16px;
  cursor: pointer;
}

/* Style for the cancel button */
.cancel-button {
  background-color: #d62912; 
  color: #fff; 
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0 10px; 
  font-size: 16px;
  cursor: pointer;
}

/* Style for the deny button */
.deny-button {
  background-color: #441fd9;
  color: #fff; 
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0 10px; 
  font-size: 16px;
  cursor: pointer;
}

/* Apply spacing between buttons */
.swal2-actions {
  display: flex;
  gap: 10px; /* Space between buttons */
}

.no-background {
  background-color: transparent !important;
}

.custom-placeholder::placeholder {
  font-size: 14px; /* Adjust the size as needed */
  color: #6c757d; /* Optional: You can also change the color */
}

.buttonStyle {
padding: 3px 12px !important;
font-size: 12px !important;
}

.css-qx0nb3-MuiDataGrid-root .MuiDataGrid-cell {
  line-height: unset;
}

.po-details-table .MuiDataGrid-columnHeaders{
  border: 2px solid #000;
}
.po-details-table .MuiDataGrid-columnHeader{
  border-right: 2px solid #000 !important;
  background-color: #0d6efd;
  color: #fff;
}
.po-details-table .MuiDataGrid-row{
  border: 2px solid #000;
  border-bottom: 1px solid #000 !important;
}
.po-details-table .MuiDataGrid-cell{
  border-right: 2px solid #000 !important;
}